<template>
  <div class="page-padding py-7">
    <BreadcrumbComponent />
    <div class="d-flex">
      <v-text-field
        v-model="dateRange"
        class="callback inline-middle right-gap-sm"
        variant="outlined"
        color="primary"
        density="compact"
        style="max-width: 200px;"
        @click="handleDateRangePickerClicked"
        readonly
      />
      <v-select
        v-model="timeGranularity"
        class="inline-middle mt-0 right-gap-sm"
        variant="outlined"
        color="primary"
        density="compact"
        style="max-width: 150px;"
        :items="timeGranularities"
      />
      <CustomSelect
        name-field="title"
        value-field="value"
        class="inline-middle mt-0 right-gap-sm"
        style="width: 250px;"
        :placeholder="'classificationAgents.select_classification_model'"
        :items="classificationModelsItems"
        :selected="selectedClassificationModelId"
        @selected-changed="onClassificationModelSelectorChange"
        @get-more="fetchClassificationModels"
        clickable
      />
      <CustomSelect
        name-field="title"
        value-field="value"
        class="inline-middle mt-0 right-gap-sm"
        style="width: 250px;"
        :placeholder="'classificationAgents.classification_agent'"
        :items="classificationAgentsItems"
        :selected="selectedClassificationAgentId"
        @selected-changed="onClassificationAgentSelectorChange"
        @get-more="fetchClassificationAgents"
        clickable
      />
      <CustomSelect
        name-field="title"
        value-field="value"
        class="inline-middle mt-0 right-gap-sm"
        style="width: 250px;"
        :placeholder="'home.workflows'"
        :items="workflowsItems"
        :selected="selectedWorkflowId"
        @selected-changed="onWorkflowSelectorChange"
        @get-more="fetchWorkflows"
        clickable
      />
    </div>
    <div class="d-flex align-center justify-space-between w-100">
      <v-card
        class="chart-card top-gap-lg pb-1"
        style="width: 53%; height: 70%;"
      >
        <h3 class="text-h3 text-center">
          Classification
        </h3>
        <div
          class="d-flex justify-space-between align-center"
          style="height: 320px;"
        >
          <v-btn
            icon="fas fa-chevron-left"
            variant="text"
            density="comfortable"
            :disabled="isPreviousDisabled"
            @click="handlePreviousData"
          />
          <div class="w-full">
            <VueApexCharts
              width="100%"
              height="300px"
              type="bar"
              :options="getChartOptions()"
              :series="[metricsChartSeries[index]]"
            />
          </div>
          <v-btn
            icon="fas fa-chevron-right"
            variant="text"
            density="comfortable"
            :disabled="isNextDisabled"
            @click="handleNextData"
          />
        </div>
      </v-card>
    </div>
    <DateRangeSelectDialog
      v-model="dateRangeDialog"
      :from-date="new Date(fromDate)"
      :to-date="new Date(toDate)"
      @confirm="onDateRangeSelected"
    />
  </div>
</template>

<script>
import moment from 'moment';
import VueApexCharts from 'vue3-apexcharts';
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import CustomSelect from '@/components/common/elements/Forms/CustomSelect.vue';
import DateRangeSelectDialog from '@/components/extract/elements/Stats/DateRangeSelectDialog';

import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import { ClassificationAgentsAPI } from '@/API/classify/ClassificationAgentsAPI';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import { MetricsAPI } from '@/API/authenticator/MetricsAPI';

export default {
  name: 'MetricsView',

  components: {
    CustomSelect,
    BreadcrumbComponent,
    DateRangeSelectDialog,
    VueApexCharts,
  },

  data() {
    return {
      sortDesc: true,
      offset: 0,
      totalWorkflows: 0,
      timeGranularity: "month",
      timeGranularities: [
        { title: this.$t('metrics.day'), value: 'day', format: 'DD/MM/YYYY' },
        { title: this.$t('metrics.week'), value: 'week', format: 'DD/MM/YYYY' },
        { title: this.$t('metrics.month'), value: 'month', format: 'MM/YYYY' },
      ],
      selectedClassificationModelId: null,
      selectedClassificationAgentId: null,
      selectedWorkflowId: null,
      classificationModels: [],
      classificationAgents: [],
      workflows: [],
      dateRangeDialog: false,
      fromDate: moment().subtract(1, 'year'),
      toDate: moment(),
      classifyMetrics: [{
        title: this.$t('metrics.document_classification'),
        metrics: [],
      }, {
        title: this.$t('metrics.bundle_classification'),
        metrics: [],
      }],
    };
  },

  computed: {
    classificationModelsItems() {
      return this.classificationModels.map((model) => ({
        title: model.name,
        value: model.id,
      }));
    },

    classificationAgentsItems() {
      return this.classificationAgents.map((agent) => ({
        title: agent.name,
        value: agent.id,
      }));
    },

    workflowsItems() {
      return this.workflows.map((workflow) => ({
        title: workflow.name,
        value: workflow.id,
      }));
    },

    dateRange() {
      return `${this.fromDate.format('YYYY-MM-DD')} - ${this.toDate.format('YYYY-MM-DD')}`;
    },

    metricsChartSeries() {
      return this.classifyMetrics.map(metric => ({
        name: metric.title,
        data: metric.metrics.map(m => m.total_documents).reverse()
      }));
    },

    isNextDisabled() {
      return this.offset <= 0;
    },

    isPreviousDisabled() {
      return this.offset >= this.totalMetrics - 6;
    },
  },

  watch: {
    timeGranularity: {
      handler: 'fetchMetrics',
      immediate: true
    },
    selectedClassificationModelId: {
      handler: 'fetchMetrics',
      immediate: true
    },
    selectedClassificationAgentId: {
      handler: 'fetchMetrics',
      immediate: true
    },
    selectedWorkflowId: {
      handler: 'fetchMetrics',
      immediate: true
    },
  },

  created() {
    this.setBreadcrumb();
    this.fetchClassificationModels();
    this.fetchClassificationAgents();
    this.fetchWorkflows();
    this.fetchMetrics();
  },

  methods: {

    handleDateRangePickerClicked() {
      this.dateRangeDialog = true;
    },

    handlePreviousData() {
      this.offset += 1;
      this.fetchMetrics();
    },

    handleNextData() {
      this.offset -= 1;
      this.fetchMetrics();
    },

    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
          { title: this.$t('menu.monitoring') },
          { title: this.$t('menu.metrics') },
        ]
      );
    },

    onDateRangeSelected(fromDate, toDate) {
      this.toDate = moment(toDate);
      this.fromDate = moment(fromDate);
      this.fetchMetrics();
    },

    onClassificationModelSelectorChange(classificationModelId) {
      if (this.selectedClassificationModelId === classificationModelId) {
        return;
      }
      this.selectedClassificationModelId = classificationModelId;
      this.selectedClassificationModel = this.classificationModels.find((model) => model.id === this.selectedClassificationModelId);
    },

    onClassificationAgentSelectorChange(classificationAgentId) {
      if (this.selectedClassificationAgentId === classificationAgentId) {
        return;
      }
      this.selectedClassificationAgentId = classificationAgentId;
    },

    onWorkflowSelectorChange(workflowId) {
      if (this.selectedWorkflowId === workflowId) {
        return;
      }
      this.selectedWorkflowId = workflowId;
    },

    async fetchClassificationModels(filter = '', reset = true) {
      if (this.offset > this.totalModels) {
        return;
      }
      if (reset) {
        this.offset = 0;
        this.classificationModels = [];
      } else {
        this.offset += this.limit;
      }
      const response = await ClassifyModelAPI.getClassificationModels({name: filter, offset: this.offset, limit: this.limit});
      const classificationModels = response.data.filter((model) => model.id !== this.selectedClassificationModelId);
      this.classificationModels = this.classificationModels.concat(...classificationModels);
      this.totalModels = parseInt(response.headers['x-total-count'], 10);
    },

    async fetchClassificationAgents(filter = '', reset = true) {
      if (this.offset > this.totalAgents) {
        return;
      }
      if (reset) {
        this.offset = 0;
        this.classificationAgents = [];
      } else {
        this.offset += this.limit;
      }
      const response = await ClassificationAgentsAPI.fetchAll({name: filter, offset: this.offset, limit: this.limit});
      const classificationAgents = response.data.filter((agent) => agent.id !== this.selectedClassificationAgentId);
      this.classificationAgents = this.classificationAgents.concat(...classificationAgents);
      this.totalAgents = parseInt(response.headers['x-total-count'], 10);
    },

    async fetchWorkflows(filter = '', reset = true) {
      if (this.offset > this.totalWorkflows) {
        return;
      }

      if (reset) {
        this.offset = 0;
        this.workflows = [];
      } else {
        this.offset += this.limit;
      }

      const [data, total] = await WorkflowAPI.getByUUID(null, filter, this.sortDesc, this.offset, this.limit);
      const workflows = data.filter((workflow) => workflow.id !== this.selectedWorkflowId);
      this.workflows = this.workflows.concat(...workflows);
      this.totalWorkflows = total;
    },

    getChartOptions() {
      return {
        chart: {
          height: '100%',
          toolbar: {
            show: false,
          },
          type: 'bar',
          stacked: false,
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
          }
        },
        colors: [
          '#6C5DD3',
          '#514A9D',
          '#8F88FF',
          '#C4B5FD',
          '#FF8A48',
        ],
        xaxis: {
          categories: this.classifyMetrics[0].metrics
            .map((metric) => moment(metric.timestamp)
              .format(this.timeGranularities
                .find((granularity) => granularity.value === this.timeGranularity)
                .format))
            .reverse(),
          labels: {
            rotate: -45,
            rotateAlways: true,
            style: {
              fontSize: '12px'
            }
          }
        },
        yaxis: {
          max: (max) => max + max * 0.1,
          forceNiceScale: true,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
        },
      };
    },

    fetchMetrics() {
      const actions = ['classification', 'bundle_classification'];
      actions.forEach(async (action, index) => {
        try {
          const response = await MetricsAPI.get({
            app: "classify",
            action,
            time_granularity: this.timeGranularity,
            timestamp_from: this.fromDate.format('YYYY-MM-DD'),
            timestamp_to: this.toDate.format('YYYY-MM-DD'),
            model_id: this.selectedClassificationModelId,
            agent_id: this.selectedClassificationAgentId,
            workflow_id: this.selectedWorkflowId,
            offset: this.offset,
          });
          if (this.activeTab === 'classify') {
            this.classifyMetrics[index].metrics = response.metrics;
            this.classifyMetrics[index].total = response.total;
          } else {
            this.extractMetrics[index].metrics = response.metrics;
            this.extractMetrics[index].total = response.total;
          }
        } catch (error) {
          this.classifyMetrics[index].metrics = [];
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  .warning-message {
    padding: 16px;
    width: 650px;
    color: rgb(var(--v-theme-primary));
    border: solid 1px rgb(var(--v-theme-primary));
    border-radius: 10px;
    background-color: rgb(var(--v-theme-primary-lighten2));
  }
</style>
