<template>
  <div class="annotation-highlights">
    <div
      class="stretch"
      @click="dropdownOn = -1"
      @mouseup="event => $emit('drawEnd', event)"
      @mousedown="event => $emit('drawStart', event)"
      @mousemove="event => $emit('drawMove', event)"
    />
    <div
      v-for="label in otherAnnotations"
      :key="label.name"
    >
      <div
        v-for="(annotation, n) in annotations[label]"
        :key="n"
      >
        <div v-if="annotation.page_nb === currentPage">
          <div
            v-for="(location, o) in annotation.location"
            :key="o"
          >
            <div
              class="other-annotation"
              :style="{
                left: `${getHighlightX(location)}vh`,
                top: `${getHighlightY(location)}vh`,
                width: `${getWidth(location)}vh`,
                height: `${getHeight(location)}vh`,
                'background-color': `${getColor(label)}44`,
              }"
              @click="$emit('selectLabel', label)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="label in filteredAnnotations"
      :key="label.name"
      :ref="`label${label.name}`"
    >
      <div
        v-for="(annotation, j) in annotations[label]"
        :key="j"
      >
        <div v-if="annotation.page_nb === currentPage">
          <div
            v-for="(location, k) in annotation.location"
            :key="k"
          >
            <div
              :ref="`annotation${annotations[label][j].id}`"
              class="annotation"
              :style="{
                left: `${getHighlightX(location)}vh`,
                top: `${getHighlightY(location)}vh`,
                width: `${getWidth(location)}vh`,
                height: `${getHeight(location)}vh`,
                'outline-color': getColor(label),
                'background-color': `${getColor(label)}44`,
              }"
              @click="$emit('selectLabel', label)"
            >
              <div
                v-if="!editing"
                class="annotation-delete"
                style="max-width: 15px; max-height: 15px;"
                :style="{
                  height: getDeleteSize(location),
                  width: getDeleteSize(location),
                  right: `calc(-1 * ${getDeleteSize(location)})`,
                  'background-color': getColor(label),
                }"
                @click="$emit('deleteAnnotation', [annotation.id, label])"
              >
                <div
                  style="position: absolute; top: 50%; transform: translateY(-50%); text-align: center; max-width: 50px;"
                  :style="{
                    width: getDeleteSize(location),
                  }"
                >
                  <v-icon
                    color="white"
                    size="7"
                  >
                    fas fa-times
                  </v-icon>
                </div>
              </div>
              <div
                class="annotation-label ellipsis clickable noselect"
                :style="{
                  'max-width': `calc(${getWidth(location) + 1}vh - 3px)`,
                  'background-color': getColor(label),
                }"
                @click="dropdownOn = dropdownOn === annotation.id ? -1 : annotation.id"
              >
                {{ label }}
              </div>
              <div
                v-if="dropdownOn === annotation.id"
                class="annotation-label-select noselect"
                :style="{
                  'max-width': `calc(${getWidth(location) + 1}vh - 3px)`,
                  'background-color': `${getColor(label)}`,
                }"
              >
                <div
                  v-for="(labelOption, l) in labels"
                  :key="l"
                >
                  <div
                    v-if="label !== labelOption"
                    class="annotation-label-select-item"
                    style="padding: 3px"
                    @click="$emit('changeLabel', [annotation.id, labelOption, label])"
                  >
                    {{ labelOption }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnnotationHighlights',

  data() {
    return {
      dropdownOn: -1,
    }
  },

  computed: {
    filteredAnnotations() {
      if (this.onlyShowLabels.length > 0) {
        return Object.keys(this.annotations).filter(a => this.onlyShowLabels.includes(a));
      }
      return Object.keys(this.annotations);
    },

    otherAnnotations() {
      if (this.onlyShowLabels.length > 0) {
        return Object.keys(this.annotations).filter(a => !this.onlyShowLabels.includes(a));
      }
      return [];
    }
  },

  methods: {
    getDeleteSize(location) {
      const size = this.getHeight(location);
      if (document.documentElement.clientHeight * (size / 100) >= 15) {
        return '15px';
      }
      return `${size}vh`
    },

    getColor(label) {
      return this.$store.getters.annotationColors[this.labels.indexOf(label) % 9];
    },

    getHighlightX(location) {
      return location.x_min * this.pageWidth;
    },

    getHighlightY(location) {
      return location.y_min * this.pageHeight;
    },

    getWidth(location) {
      return (location.x_max - location.x_min) * this.pageWidth;
    },

    getHeight(location) {
      return (location.y_max - location.y_min) * this.pageHeight;
    },
  },

  props: {
    editing: {
      type: Boolean,
      required: true,
    },

    annotations: {
      type: Object,
      required: true,
    },

    onlyShowLabels: {
      type: Array,
      required: true,
    },

    currentPage: {
      type: Number,
      required: true,
    },

    pageWidth: {
      type: Number,
      required: true,
    },

    pageHeight: {
      type: Number,
      required: true,
    },

    labels: {
      type: Array,
      required: true,
    },
  },

  emits: [
    'drawEnd',
    'drawStart',
    'drawMove',
    'selectLabel',
    'deleteAnnotation',
    'changeLabel',
  ],
}
</script>

<style lang="scss" scoped>
.annotation-highlights {
  .other-annotation {
    position: absolute;
    border: none;
    background-color: #4e2bff44;
    border-radius: 3px;
    cursor: pointer !important;
  }

  .annotation {
    position: absolute;
    outline: rgb(var(--v-theme-primary)) solid 3px;
    background-color: #4e2bff44;
    border-radius: 0px 0px 3px 3px;
    cursor: default !important;

    .annotation-delete {
      position: absolute;
      background-color: rgb(var(--v-theme-primary));
      top: -3px;
      border-radius: 0px 3px 3px 0px;
      cursor: pointer !important;
    }

    .annotation-label {
      position: absolute;
      background-color: rgb(var(--v-theme-primary));
      height: 15px;
      top: -16px;
      left: -3px;
      width: fit-content;
      border-radius: 3px 3px 0px 0px;
      text-transform: uppercase;
      color: white;
      font-size: 7px;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 3px;
      cursor: pointer;
    }

    .annotation-label-select {
      position: absolute;
      top: -3px;
      left: -3px;
      font-size: 9px;
      color: white;
      text-transform: uppercase;
    }

    .annotation-label-select-item:hover {
      background-color: white;
      color: black;
    }
  }
}
</style>