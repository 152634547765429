<template>
  <div class="extractor-config-toolbox">
    <div class="inline-middle">
      {{ $t("docViewer.show") }}
    </div>
    <v-radio-group
      class="inline-middle page-info-radio"
      :value="tab"
      @update:model-value="handleChange"
      inline
    >
      <v-radio
        v-for="(info, i) in availableInfo"
        :key="info"
        :class="{'right-gap': i < availableInfo.length - 1}"
        :value="info"
        :label="$t(`dataPoints.${info}`)"
      />
    </v-radio-group>
    <v-select
      v-if="modelValue === 'text'"
      class="left-gap inline-middle"
      style="width: 150px; margin-top: 0px; color: black;"
      variant="outlined"
      color="primary"
      density="compact"
      :value="readingOrder"
      :items="[
        { title: 'Default', value: 'default' },
        { title: 'Roma2', value: 'roma2' },
        { title: 'Roma3', value: 'roma3' },
      ]"
      @update:model-value="updateValue"
    />
    <v-checkbox
      v-if="modelValue === 'text'"
      class="left-gap inline-middle top-gap"
      label="Use Tables"
      color="primary"
      :input-value="useTables"
      @update:model-value="value => $emit('update:useTables', value)"
    />
  </div>
</template>

<script>
export default {
  name: 'ExtractorConfigToolbox',

  data() {
    return ({
      readingOrder: this.selectedReadingOrder,
      tab: this.modelValue,
    });
  },

  watch: {
    modelValue(value) {
      this.tab = value;
    },

    readingOrder(order) {
      this.$emit('update:selectedReadingOrder', order);
    },

    selectedReadingOrder(order) {
      this.readingOrder = order;
    },
  },

  methods: {
    updateValue(value) {
      this.$emit('update:selectedReadingOrder', value);
    },

    handleChange(value) {
      this.$emit('update:modelValue', value);
    },
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    selectedReadingOrder: {
      type: String,
      required: true,
    },

    useTables: {
      type: Boolean,
    },

    availableInfo: {
      type: Array,
      required: true,
    },
  },

  emits: [
    'update:selectedReadingOrder',
    'update:useTables',
    'update:modelValue',
  ],
}
</script>

<style lang="scss" scoped>
  .extractor-config-toolbox {
    margin-top: -22px;

    .v-radio {
      color: white !important;

      .v-label {
        color: white !important;
      }

      &::v-deep * {
        color: white !important;
      }
    }

    .page-info-radio {
      padding: 8px;
      padding-left: 10px;
      padding-right: 0px;
      width: fit-content;
    }
  }
</style>