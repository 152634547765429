<template>
  <div class="configure-dp">
    <div class="top-input position-relative">
      <HeaderName
        style="max-width: calc(100% - 190px);"
        :item="{ name: newName, id: dataPoint.id }"
        @save="saveDPName"
      />
      <v-tooltip>
        <template #activator="{ props }">
          <ButtonWithIcon
            v-bind="props"
            style="top: 3px; right: 0"
            icon="far fa-clone"
            span-class="text-no-wrap"
            class="position-absolute fade-in"
            :message="$t('dataPoints.generative.clone_generative')"
            @click="setGenerativeCopy(dataPoint)"
            rounded
          />
        </template>
        {{ $t('dataPoints.generative.clone_generative_message') }}
      </v-tooltip>
      <div class="top-gap">
        <div class="label mb-2">
          {{ $t('dataPoints.description') }}
        </div>
        <TextEditor
          ref="descriptionEditor"
          class="w-100"
          rows="2"
          :text="extractorDescription"
          @save="description => $emit('changeDescription', description)"
        />
      </div>
    </div>
    <ValueTypeConfig
      class="top-gap-sm"
      :data-point="dataPoint"
      :value-type="dataPoint.value_type"
      :extraction-type="extractionType"
      @change="$emit('changeValueType')"
      @validity-change="(valid) => valueTypeValid = valid"
    />
    <div
      :class="{
        'top-gap-sm': dataPoint.category === 'generative',
      }"
    >
      <div class="label">
        {{ $t('dataPoints.extract_method') }}
      </div>
      <div style="margin-top: 5px">
        <v-radio-group
          v-model="extractionType"
          hide-details
          inline
        >
          <div class="inline-middle radio-box right-gap-sm bottom-gap-sm">
            <v-radio
              value="model"
              :label="$t('dataPoints.model_option')"
            />
          </div>
          <div class="inline-middle radio-box right-gap-sm bottom-gap-sm">
            <v-radio
              value="rules"
              :label="$t('dataPoints.rules')"
            />
          </div>
          <div class="inline-middle radio-box right-gap-sm bottom-gap-sm">
            <v-radio
              value="group_based"
              :label="$t('dataPoints.group_option')"
              :disabled="dataPoint.value_type !== 'unspecified'"
              :style="{opacity: dataPoint.value_type !== 'unspecified' ? 0.5 : 1}"
            />
          </div>
          <div class="inline-middle radio-box right-gap-sm bottom-gap-sm">
            <v-radio
              value="generative"
              :label="$t('dataPoints.generative.title')"
            />
          </div>
        </v-radio-group>
        <GenerativeWarning v-if="extractionType === 'generative'" />
      </div>
    </div>
    <div v-if="['rules', 'model'].includes(extractionType) && dataPoint.value_type === 'unspecified'">
      <div
        class="inline-middle radio-box right-gap-sm bottom-gap-sm"
        style="margin-top: 5px"
      >
        <v-checkbox
          v-model="dataPoint.return_block"
          style="margin-top: -7px"
          color="primary"
          :label="$t('dataPoints.return_block')"
        />
      </div>
      <div
        class="inline-middle radio-box right-gap-sm bottom-gap-sm"
        style="margin-top: 5px"
      >
        <v-checkbox
          v-model="dataPoint.return_table_cell"
          style="margin-top: -7px"
          color="primary"
          :label="$t('dataPoints.return_table_cell')"
        />
      </div>
    </div>
    <div v-if="extractionType === 'rules'">
      <div
        v-for="(path, j) in displayPaths"
        :key="j"
      >
        <ExtractionPath
          v-if="EPTab > -1"
          :path="path"
          @refresh="displayPaths = [...displayPaths]"
          @activate-forms="$emit('activate', 'form')"
          @activate-layout="$emit('activate', 'layout')"
          @activate-tables="$emit('activate', 'tables')"
          @activate-default="$emit('activate', 'default')"
          @validity-change="checkValidity"
        />
      </div>
    </div>
    <DPModels
      v-else-if="extractionType === 'model'"
      :initial-model="selectedModel"
      :initial-model-version="selectedModelVersion"
      :initial-entity="selectedCustomEntity"
      @model-change="model => selectedModel = model"
      @version-change="version => selectedModelVersion = version"
      @entity-change="entity => selectedCustomEntity = entity"
    />
    <div v-else-if="extractionType === 'group_based' && dataPoint.extraction_group">
      <ExtractionGroupConfig
        v-if="dataPoint.extraction_group.custom_model_id !== 0 && dataPoint.extraction_group.custom_model_version"
        type="data_point"
        :group="dataPoint.extraction_group"
        @update-labels="(labels) => $emit('updateLabels', labels)"
      />
      <DPModels
        v-else
        :initial-model="dataPoint.extraction_group.custom_model_id"
        :show-entity="false"
        @model-change="model => dataPoint.extraction_group.custom_model_id = model"
        @version-change="version => dataPoint.extraction_group.custom_model_version = version"
      />
    </div>
    <GenerativeExtractorLabels
      v-else-if="extractionType === 'generative' && dataPoint.labels"
      :labels="dataPoint.labels"
      @update-labels="labels => $emit('updateLabels', labels)"
    />
  </div>
  <DataPointNameDialog
    v-model="showNameDialog"
    :initial-name="generativeCopy?.name"
    @close="showNameDialog = false; generativeCopy = null"
    @save="(name) => handleCopyGenerative(generativeCopy, name)"
  />
</template>

<script>
import { http } from '@/plugins/axios';
import DPModels from '@/components/extract/elements/DataPoints/DPModels';
import ExtractionPath from '@/components/extract/elements/DataPoints/ExtractionPath';
import HeaderName from '@/components/common/elements/General/HeaderName';
import ValueTypeConfig from '@/components/extract/elements/DataPoints/ValueTypeConfig';
import ExtractionGroupConfig from '@/components/extract/elements/LabelGroups/ExtractionGroupConfig';
import GenerativeExtractorLabels from '@/components/extract/elements/LabelGroups/GenerativeExtractorLabels';
import TextEditor from '@/components/common/elements/General/TextEditor';
import ButtonWithIcon from '@/components/common/elements/Forms/ButtonWithIcon';
import DataPointNameDialog from '@/components/extract/elements/DataPoints/DataPointNameDialog';
import GenerativeWarning from '@/components/extract/elements/Extractors/GenerativeWarning.vue';

import { copyGenerative } from '@/utils/ExtractorUtils';

export default {
  name: 'ConfigureDP',

  components: {
    ExtractionPath,
    HeaderName,
    DPModels,
    ValueTypeConfig,
    ExtractionGroupConfig,
    TextEditor,
    GenerativeExtractorLabels,
    ButtonWithIcon,
    DataPointNameDialog,
    GenerativeWarning,
  },

  data() {
    return ({
      allValid: false,
      selectedCustomEntity: '',
      selectedModel: 0,
      selectedModelVersion: 0,
      extractionType: 'model',
      EPTab: -1,
      displayPaths: [],
      newName: '',
      addingBlock: false,
      blockToAdd: '',
      valueTypeValid: true,
      showNameDialog: false,
      generativeCopy: null,
    })
  },

  computed: {
    extractorDescription: {
      get() {
        return this.dataPoint.description || '';
      },

      set(newValue, oldValue) {
        if (newValue !== oldValue && oldValue) {
          this.$emit('changeDescription', newValue);
        }
      },
    },

    selectedPath: {
      get() {
        return this.displayPaths[this.EPTab]
      },
      set(value) {
        const displayPaths = [...this.displayPaths];
        displayPaths[this.EPTab] = value;
        this.displayPaths = [...displayPaths];
      }
    },
  },

  watch: {
    'dataPoint.value_type': {
      handler(newType) {
        if (newType !== 'unspecified') {
          this.dataPoint.return_block = false;
          this.dataPoint.return_table_cell = false;
        }
      },
    },

    'dataPoint.return_block': {
      handler(newValue) {
        if (newValue) {
          this.dataPoint.return_table_cell = false;
        }
      },
    },

    'dataPoint.return_table_cell': {
      handler(newValue) {
        if (newValue) {
          this.dataPoint.return_block = false;
        }
      },
    },

    valueTypeValid() {
      this.checkValidity();
    },

    allValid() {
      this.$emit('validityChange', this.allValid);
    },

    selectedCustomEntity() {
      this.$emit('changeCustomEntity', this.selectedCustomEntity);
    },

    extractionType() {
      this.$emit('changeType', this.extractionType);
    },

    name() {
      this.newName = this.name;
    },

    paths: {
      handler() {
        if (this.paths.length > 0) {
          this.displayPaths = [...this.paths];
        }
      },
      deep: true
    },

    displayPaths: {
      handler() {
        this.displayPaths.forEach((path, i) => {
          path.name = `Path ${i + 1}`
          path.ordinal = i;
        });
        if (this.EPTab < 0) {
          this.EPTab = 0
        }
        if (this.EPTab >= this.displayPaths.length) {
          this.EPTab = this.displayPaths.length - 1;
        }
      },
      deep: true
    },
  },

  mounted() {
    this.newName = this.name;
    if (this.dataPoint.category === 'classic') {
      this.extractionType = 'rules';
    } else if (this.dataPoint.category === 'group_based') {
      this.extractionType = 'group_based';
    } else if (this.dataPoint.category === 'generative') {
      this.extractionType = 'generative';
    }
    if (this.paths.length > 0) {
      const customEntities = this.paths[0].what.custom_entity;
      if (this.paths.length === 1 && customEntities && customEntities.length === 1) {
        this.selectedModel = customEntities[0][0];
        this.selectedCustomEntity = customEntities[0][1];
        this.selectedModelVersion = this.paths[0].what.custom_model_version || 1;
      }
      this.displayPaths = [...this.paths];
    } else {
      this.addNew();
    }
    this.$nextTick(() => {
      this.extractorDescription = this.dataPoint.description || '';
    });
  },

  methods: {
    setGenerativeCopy(dataPoint) {
      this.generativeCopy = dataPoint;
      this.showNameDialog = true;
    },

    async handleCopyGenerative(extractor, name) {
      try {
        this.$store.commit('setLoadingScreen', true);
        const agentId = extractor.document_type_id || this.$route.params.id;
        const newId = await copyGenerative(extractor, name, agentId);
        this.showNameDialog = false;
        this.generativeCopy = null;
        this.$store.commit('setSuccessMessage', this.$t('dataPoints.generative.cloned'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$router.push({
          name: 'DatapointConfig',
          params: {
            id: agentId,
            itemId: newId,
          }
        });
      } catch (error) {
        this.$store.commit('setSnackbar', true);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async saveDPName(name) {
      try {
        await http.put(
          `system_2/data_point/${this.id}`, { name }
        );
        this.newName = name;
        this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.updated'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('nameSave', name);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    checkValidity() {
      this.allValid = this.valueTypeValid &&
        this.displayPaths.every(path => !path.invalid);
    },

    handleAddButton() {
      this.addingBlock = true;
      setTimeout(() => {
        this.$refs.blockSelect[0].activateMenu();
      }, 10);
    },

    saveName() {
      if (this.newName.length >= 3) {
        this.saveDataPoint();
      }
    },

    saveDataPoint(newDataPoint = {}) {
      if (this.isValid) {
        if (this.extractionType === 'model') {
          const extraction_paths = [{
            what: {
              custom_entity: [[this.selectedModel, this.selectedCustomEntity]],
              custom_model_version: this.selectedModelVersion,
              regex: null,
              entity: null,
            },
            where: {
              page_num: [],
            },
          }];
          const pathsLength = this.displayPaths.length;
          if (pathsLength > 0) {
            const id = this.displayPaths[0].id;
            if (id) {
              extraction_paths[0].id = id;
              for (let i = 1; i < pathsLength; i++) {
                this.$emit('removePath', [this.displayPaths[i]]);
              }
            }
          }
          this.$emit(
            'save',
            {
              ...this.dataPoint,
              ...newDataPoint,
              extraction_paths,
              category: 'custom',
            }
          );
          return;
        } else if (this.extractionType === 'rules') {
          let pathsToSave = [];
          pathsToSave = [...this.displayPaths];
          this.displayPaths.forEach((path, i) => {
            if ((path.where.nearby_text && (path.where.nearby_text.same_line.length === 0 && path.where.nearby_text.same_column.length === 0 && path.where.nearby_text.around.length === 0))
            ) {
              pathsToSave[i].where.nearby_text = null;
            }

            if (path.where.form && path.where.form.keys && path.where.form.keys.length === 0) {
              pathsToSave[i].where.form = null;
            }

            if (!path.where.layout || !path.where.layout.tag || path.where.layout.tag.length === 0) {
              pathsToSave[i].where.layout = null;
            }

            if (path.where.table && path.where.table.col_num && path.where.table.col_num.length === 0) {
              pathsToSave[i].where.table.col_num = null;
            }

            if (path.where.table && path.where.table.row_num && path.where.table.row_num.length === 0) {
              pathsToSave[i].where.table.row_num = null;
            }

            if (path.where.table && path.where.table.row_names && path.where.table.row_names.length === 0) {
              pathsToSave[i].where.table.row_names = null;
            }

            if (path.where.table && path.where.table.col_names && path.where.table.col_names.length === 0) {
              pathsToSave[i].where.table.col_names = null;
            }

            if (path.where.table && !path.where.table.row_names && !path.where.table.col_names && !path.where.table.row_num && !path.where.table.col_num) {
              pathsToSave[i].where.table = null;
            }
          });
          this.$emit(
            'save',
            {
              ...this.dataPoint,
              ...newDataPoint,
              name: this.newName.length >= 3 ? this.newName : null,
              extraction_paths: pathsToSave,
              category: 'classic',
            }
          );
        } else if (this.extractionType === 'generative') {
          this.$emit(
            'save',
            {
              ...this.dataPoint,
              ...newDataPoint,
              category: 'generative',
              description: this.extractorDescription,
            }
          );
        } else if (this.extractionType === 'group_based') {
          this.$emit(
            'save',
            {
              ...this.dataPoint,
              ...newDataPoint,
              extraction_group: this.dataPoint.extraction_group,
              category: 'group_based',
            }
          );

        }
      }
    },

    removePath(j) {
      const removed = this.displayPaths.splice(j, 1);
      this.$emit('removePath', removed);
    },

    addNew() {
      this.displayPaths.push({
        what: {
          regex: [],
          entity: null,
        },
        where: {
          page_num: [],
        }
      })
      this.EPTab = this.displayPaths.length - 1;
      this.displayPaths = [...this.displayPaths];
    },

    duplicatePath(j) {
      const clone = JSON.parse(JSON.stringify(this.displayPaths[j]));
      delete clone.id;
      this.displayPaths.splice(j + 1, 0, clone);
      setTimeout(() => {
        this.EPTab = j + 1;
      }, 10);
      this.displayPaths = [...this.displayPaths];
    },
  },

  props: {
    id: {
      type: Number,
      required: true,
    },

    isValid: {
      type: Boolean,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    paths: {
      type: Array,
      required: true,
    },

    docLoading: {
      type: Boolean,
      required: true,
    },

    dataPoint: {
      type: Object,
      required: true,
    }
  },

  emits: [
    'changeValueType',
    'activate',
    'updatePrimary',
    'validityChange',
    'changeCustomEntity',
    'changeType',
    'changeDescription',
    'nameSave',
    'removePath',
    'save',
    'updateLabels',
  ],
}
</script>

<style lang="scss" scoped>
.configure-dp {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 100%;
  position: relative;
  padding-bottom: 60px;

  .top-input {
    max-width: 100%;
  }

  .info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 6px 17px;
    padding-bottom: 10px;
    width: fit-content;
    max-width: calc(100% - 170px);

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }
}
</style>
