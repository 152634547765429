<template>
  <div class="page-padding py-7">
    <BackLink
      :text="$t('classificationAgents.backlinkLabel')"
      @click="goToClassificationAgentsView"
    />
    <div class="classification-agent-editor__actions top-gap">
      <v-text-field
        v-model="classificationAgent.name"
        class="classification-agent-name-input"
        variant="outlined"
        color="primary"
        density="compact"
        :hint="isNameValid ? '' : `${$t('forms.name_hint')} ${$t('forms.unique_hint')}`"
        :placeholder="$t('classificationAgents.type_new_classification_agent_name')"
        @keydown.enter="saveClassificationAgent"
      />
      <v-btn
        color="primary"
        class="left-gap mt-1"
        :disabled="isSaveDisabled"
        @click="saveClassificationAgent"
        rounded
      >
        {{ $t('save') }}
      </v-btn>
    </div>
    <v-card class="top-gap">
      <v-form ref="form">
        <v-row>
          <v-col
            cols="12"
            md="5"
          >
            <h4>
              {{ $t('classificationAgents.model_configuration') }}
            </h4>
            <p class="configuration-subtitle">
              {{ $t('classificationAgents.model_configuration_subtitle') }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="7"
            class="d-flex flex-column ga-4"
          >
            <div>
              <h4 class="label mb-1">
                {{ $t('classificationAgents.select_classification_model') }}
              </h4>
              <CustomSelect
                name-field="title"
                value-field="value"
                :placeholder="'classificationAgents.select_classification_model'"
                :items="classificationModelsItems"
                :selected="selectedClassificationModelId"
                @selected-changed="onClassificationModelSelectorChange"
                @get-more="fetchClassificationModels"
                clickable
              />
            </div>
            <div v-if="selectedClassificationModel">
              <h4 class="label mb-1">
                {{ $t('classificationAgents.rename_categories') }}
              </h4>
              <v-card
                variant="outlined"
                max-height="220px"
                class="card-with-no-shadow border-thin overflow-y-auto mb-4"
              >
                <div
                  v-for="category in selectedClassificationModel.categories"
                  :key="category"
                  class="d-flex flex-row ga-4 align-start category-rename-row"
                >
                  <div class="d-flex align-center category-name label font-weight-bold">
                    {{ category.name }}
                  </div>
                  <v-text-field
                    :key="category"
                    v-model="renamedCategories[category.name]"
                    variant="outlined"
                    color="primary"
                    density="compact"
                    :placeholder="`New name for ${category.name}`"
                    :rules="[(newCategoryName) => newCategoryNameRule(category.name, newCategoryName)]"
                  />
                </div>
              </v-card>
              <div>
                <h4 class="label mb-1">
                  {{ $t('classificationAgents.additional_categories') }}
                </h4>
                <v-text-field
                  v-model="newAdditionalCategory"
                  variant="outlined"
                  color="primary"
                  density="compact"
                  width="350px"
                  :placeholder="$t('classificationAgents.enter_additional_category')"
                  :rules="[additionalCategoriesRule]"
                  @keyup.enter="addNewAdditionalCategory"
                >
                  <template #append>
                    <v-btn
                      color="primary"
                      variant="outlined"
                      :disabled="hasAdditionalCategoriesError"
                      @click="addNewAdditionalCategory"
                      rounded
                    >
                      {{ $t('add') }}
                    </v-btn>
                  </template>
                </v-text-field>
                <v-chip-group column>
                  <v-chip
                    v-for="additionalCategory in additionalCategories"
                    :key="additionalCategory"
                    class="additional-class-chip"
                    @click:close="removeAdditionalCategory(additionalCategory)"
                    closable
                  >
                    {{ additionalCategory }}
                  </v-chip>
                </v-chip-group>
              </div>
            </div>
          </v-col>
        </v-row>
        <hr class="divider-line top-gap bottom-gap">
        <v-row>
          <v-col
            cols="12"
            md="5"
          >
            <h4>
              {{ $t('classificationAgents.general_settings') }}
            </h4>
            <p class="configuration-subtitle">
              {{ $t('classificationAgents.general_settings_subtitle') }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="7"
          >
            <div class="d-flex align-center justify-space-between">
              <h4 class="label">
                {{ $t('classificationAgents.classification_threshold_for_unknown') }}
              </h4>
              <v-switch
                v-model="isRelativeThreshold"
                color="primary"
                density="compact"
                :label="$t('classificationAgents.relative_threshold')"
                hide-details
              />
            </div>
            <v-text-field
              v-model="threshold"
              class="threshold-input"
              color="primary"
              density="compact"
              min="0"
              max="1"
              step="0.01"
              type="number"
              variant="outlined"
              :rules="[thresholdRule]"
            />
            <h4 class="label mb-1">
              {{ $t('classificationAgents.reject_reasons') }}
            </h4>
            <v-text-field
              v-model="newRejectReason"
              class="mb-2 align-center"
              variant="outlined"
              color="primary"
              density="compact"
              @keyup.enter="addRejectReason"
              hide-details
            >
              <template #append>
                <v-btn
                  color="primary"
                  variant="outlined"
                  :disabled="!newRejectReason"
                  @click="addRejectReason"
                  rounded
                >
                  {{ $t('add') }}
                </v-btn>
              </template>
            </v-text-field>
            <v-chip-group>
              <v-chip
                v-for="rejectReason in rejectReasons"
                :key="rejectReason"
                class="additional-class-chip"
                @click:close="removeRejectReason(rejectReason)"
                closable
              >
                {{ rejectReason }}
              </v-chip>
            </v-chip-group>
            <div>
              <ExternalLinkConfiguration
                v-model:is-external-link-support-enabled="isCorrectionExternalLinkSupportEnabled"
                v-model:token-duration="correctionExternalLinkTokenDuration"
              />
            </div>
          </v-col>
        </v-row>
        <hr class="divider-line top-gap bottom-gap">
        <v-row>
          <v-col
            cols="12"
            md="5"
          >
            <h4>
              {{ $t('classificationAgents.ocr_settings') }}
            </h4>
            <p class="configuration-subtitle">
              {{ $t('classificationAgents.ocr_settings_subtitle') }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="7"
          >
            <h4 class="label mb-1">
              {{ $t('classificationAgents.ocr_for_inference') }}
            </h4>
            <v-select
              v-model="selectedOcr"
              class="inline-middle"
              density="compact"
              variant="outlined"
              color="primary"
              :items="ocrOptions"
              full-width
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect.vue';
import { isNameValid } from '@/utils/FormValidation';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import { ClassificationAgentsAPI } from '@/API/classify/ClassificationAgentsAPI';
import { displaySnackbarSuccess } from '@/utils/SnackbarUtils';
import ExternalLinkConfiguration from '@/components/common/elements/General/ExternalLinkConfiguration.vue';

export default {
  name: 'ClassificationAgentEditor',
  components: {
    CustomSelect,
    BackLink,
    ExternalLinkConfiguration,
  },

  data() {
    return {
      classificationAgent: {
        name: '',
      },
      classificationModels: [],
      offset: 0,
      limit: 20,
      totalModels: 0,
      selectedClassificationModel: null,
      selectedClassificationModelId: null,
      newAdditionalCategory: '',
      additionalCategories: new Set(),
      hasAdditionalCategoriesError: false,
      threshold: '0.5',
      hasThresholdError: false,
      renamedCategories: {},
      hasRenamedCategoriesError: false,
      rejectReasons: new Set(),
      newRejectReason: '',
      selectedOcr: 'GOOGLE',
      ocrOptions: ['DOCTR', 'GOOGLE', 'TESSERACT', 'AZURE'],
      isRelativeThreshold: false,
      isCorrectionExternalLinkSupportEnabled: false,
      correctionExternalLinkTokenDuration: null,
    }
  },

  computed: {
    isNameValid() {
      return isNameValid(this.classificationAgent.name);
    },

    classificationModelsItems() {
      return this.classificationModels.map((model) => ({
        title: model.name,
        value: model.id,
      }));
    },

    isSaveDisabled() {
      return (
        !this.selectedClassificationModel
        || !this.isNameValid
        || this.hasThresholdError
        || this.hasRenamedCategoriesError
        || this.hasAdditionalCategoriesError
      );
    },

    trimmedRenamedCategories() {
      const trimmedRenamedCategories = {};

      Object.entries(this.renamedCategories).forEach(([key, value]) => {
        const trimmedValue = value.trim();
        if (trimmedValue) {
          trimmedRenamedCategories[key] = trimmedValue;
        }
      });

      return trimmedRenamedCategories;
    },
  },

  watch: {
    newAdditionalCategory() {
      this.$refs.form.validate();
    },
    renamedCategories: {
      deep: true,
      handler() {
        this.$refs.form.validate();
      },
    },

    isCorrectionExternalLinkSupportEnabled() {
      if (this.isCorrectionExternalLinkSupportEnabled) {
        if (this.correctionExternalLinkTokenDuration === null) {
          this.correctionExternalLinkTokenDuration = 1;
        }
      } else {
        this.correctionExternalLinkTokenDuration = null;
      }
    },

    correctionExternalLinkTokenDuration() {
      if (this.correctionExternalLinkTokenDuration) {
        this.isCorrectionExternalLinkSupportEnabled = true;
      } else {
        this.isCorrectionExternalLinkSupportEnabled = false;
      }
    },
  },

  async created() {
    if (this.id) {
      await this.initializeClassificationAgent();
    } else {
      await this.fetchClassificationModels();
    }
  },

  methods: {
    async initializeClassificationAgent() {
      const classificationAgent = await ClassificationAgentsAPI.fetchClassificationAgent({ id: this.id });
      const classificationModel = await ClassifyModelAPI.getModel(classificationAgent.model_id);
      this.classificationModels = [classificationModel];
      await this.fetchClassificationModels();

      this.selectedClassificationModelId = classificationAgent.model_id;
      this.selectedClassificationModel = classificationModel;
      this.classificationAgent.name = classificationAgent.name;
      this.threshold = classificationAgent.threshold;
      this.additionalCategories = new Set(classificationAgent.additional_categories);
      this.rejectReasons = new Set(classificationAgent.reject_reasons);
      this.selectedOcr = classificationAgent.ocr_model;
      this.renamedCategories = classificationAgent.categories_map ?? {};
      this.isRelativeThreshold = classificationAgent.is_relative_threshold;
      this.isCorrectionExternalLinkSupportEnabled = classificationAgent.correction_external_link_token_duration !== null;
      this.correctionExternalLinkTokenDuration = classificationAgent.correction_external_link_token_duration;
    },

    goToClassificationAgentsView() {
      this.$router.push({ name: 'ClassificationAgentsView' });
    },

    async fetchClassificationModels(filter = '', reset = true) {
      if (this.offset > this.totalModels) {
        return;
      }

      if (reset) {
        this.offset = 0;
        this.classificationModels = [];
      } else {
        this.offset += this.limit;
      }

      const response = await ClassifyModelAPI.getClassificationModels({name: filter, offset: this.offset, limit: this.limit});
      const classificationModels = response.data.filter((model) => model.id !== this.selectedClassificationModelId);
      this.classificationModels = this.classificationModels.concat(...classificationModels);
      this.totalModels = parseInt(response.headers['x-total-count'], 10);
    },

    onClassificationModelSelectorChange(classificationModelId) {
      if (this.selectedClassificationModelId === classificationModelId) {
        return;
      }

      this.selectedClassificationModelId = classificationModelId;

      this.selectedClassificationModel = this.classificationModels.find((model) => model.id === this.selectedClassificationModelId);
      const newRenamedCategories = {};
      if (this.selectedClassificationModel) {
        this.selectedClassificationModel.categories.forEach((category) => {
          if (!Object.prototype.hasOwnProperty.call(this.renamedCategories, category.name)) {
            newRenamedCategories[category.name] = '';
          } else {
            newRenamedCategories[category.name] = this.renamedCategories[category.name];
          }
        });
        this.renamedCategories = newRenamedCategories;
      }
    },

    addRejectReason() {
      this.rejectReasons.add(this.newRejectReason);
      this.newRejectReason = '';
    },

    removeRejectReason(rejectReason) {
      this.rejectReasons.delete(rejectReason);
    },

    thresholdRule() {
      if (this.threshold === '' || this.threshold === null || isNaN(this.threshold) || this.threshold < 0 || this.threshold > 1) {
        this.hasThresholdError = true;
        return this.$t('classificationAgents.threshold_error_message');
      }

      this.hasThresholdError = false;
      return true;
    },

    newCategoryNameRule(originalCategoryName, newCategoryName) {
      if (!newCategoryName) {
        this.hasRenamedCategoriesError = false;
        return true;
      }

      const trimmedCategoryName = newCategoryName.trim();
      if (this.additionalCategories.has(trimmedCategoryName)) {
        this.hasRenamedCategoriesError = true;
        return this.$t('classificationAgents.new_category_name_already_used_error');
      }

      const newCategoryNames = [];
      Object.entries(this.renamedCategories).forEach(([key, value]) => {
        if (key !== originalCategoryName) {
          newCategoryNames.push(value);
        }
      });

      if (newCategoryNames.includes(trimmedCategoryName)) {
        this.hasRenamedCategoriesError = true;
        return this.$t('classificationAgents.new_category_name_unique_error');
      }

      if (Object.keys(this.renamedCategories).includes(trimmedCategoryName)) {
        this.hasRenamedCategoriesError = true;
        return this.$t('classificationAgents.new_category_name_already_exists_error');
      }

      this.hasRenamedCategoriesError = false;
      return true;
    },

    additionalCategoriesRule() {
      if (!this.newAdditionalCategory) {
        this.hasAdditionalCategoriesError = false;
        return true;
      }

      if (Object.values(this.renamedCategories).includes(this.newAdditionalCategory)) {
        this.hasAdditionalCategoriesError = true;
        return this.$t('classificationAgents.new_additional_category_error');
      }

      if (Object.keys(this.renamedCategories).includes(this.newAdditionalCategory)) {
        this.hasAdditionalCategoriesError = true;
        return this.$t('classificationAgents.new_additional_category_error_2');
      }

      this.hasAdditionalCategoriesError = false;
      return true;
    },

    addNewAdditionalCategory() {
      if (this.newAdditionalCategory.trim() === '') {
        return;
      }

      this.additionalCategories.add(this.newAdditionalCategory);
      this.newAdditionalCategory = '';
    },

    removeAdditionalCategory(category) {
      this.additionalCategories.delete(category);
    },

    async saveClassificationAgent() {
      try {
        if (this.id) {
          await ClassificationAgentsAPI.updateClassificationAgent({
            id: this.id,
            name: this.classificationAgent.name,
            threshold: this.threshold,
            isRelativeThreshold: this.isRelativeThreshold,
            additionalCategories: Array.from(this.additionalCategories),
            classificationModelId: this.selectedClassificationModel.id,
            categoriesMap: this.trimmedRenamedCategories,
            rejectReasons: Array.from(this.rejectReasons),
            ocrModel: this.selectedOcr,
            correctionExternalLinkTokenDuration: this.correctionExternalLinkTokenDuration,
          });
          displaySnackbarSuccess(this.$t('classificationAgents.classification_agent_updated'));
        } else {
          await ClassificationAgentsAPI.createClassificationAgent({
            name: this.classificationAgent.name,
            threshold: this.threshold,
            isRelativeThreshold: this.isRelativeThreshold,
            additionalCategories: Array.from(this.additionalCategories),
            classificationModelId: this.selectedClassificationModel.id,
            categoriesMap: this.trimmedRenamedCategories,
            rejectReasons: Array.from(this.rejectReasons),
            ocrModel: this.selectedOcr,
            correctionExternalLinkTokenDuration: this.correctionExternalLinkTokenDuration,
          });
          this.goToClassificationAgentsView();
          displaySnackbarSuccess(this.$t('classificationAgents.classification_agent_created'));
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    }
  },

  props: {
    id: {
      type: [Number, undefined],
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .classification-agent-editor__actions {
  display: flex;
  align-items: start;

  .classification-agent-name-input {
    max-width: 390px;
  }
}

::v-deep .v-card.card-with-no-shadow {
  box-shadow: none !important;
}

::v-deep .v-input {
  margin-top: 0 !important;

  .v-input__details {
    min-height: 10px !important;
    padding-top: 2px !important;

    .v-messages__message {
      line-height: 16px !important;
    }
  }
}

.additional-class-chip {
  color: rgb(var(--v-theme-primary)) !important;
}

.category-rename-row {
  height: 60px;

  .category-name {
    width: 150px;
    height: 42px;
  }
}

.configuration-subtitle {
  font-size: 0.8rem;
}

.threshold-input {
  width: 100%;
}
</style>
