<template>
  <div>
    <div class="label text-field-label top-gap-sm bottom-gap-lg">
      {{ $t('apiToken.title') }}
    </div>
    <div
      v-for="apiToken in apiTokens"
      :key="apiToken.id"
      class="d-flex bottom-gap-sm"
      @mouseover="hoveredApiToken = apiToken.id"
      @mouseleave="hoveredApiToken = null"
    >
      <div
        class="d-flex"
        style="font-size: 0.8rem; margin-top: -20px;"
      >
        <v-text-field
          class="inline-middle mt-0 api-token-field fade-in"
          variant="outlined"
          color="primary"
          density="compact"
          :value="apiToken.api_token"
          readonly
        />
        <v-tooltip
          v-if="hoveredApiToken === apiToken.id"
          bottom
        >
          <template #activator="{ props }">
            <v-icon
              color="primary"
              class="left-gap-sm clickable"
              style="margin-top: 10px"
              size="16"
              v-bind="props"
              :disabled="!editingAllowed"
              @click="selectedApiToken = apiToken.id; revokeApiTokenDialog = true"
            >
              fas fa-times
            </v-icon>
          </template>
          {{ $t('revoke') }}
        </v-tooltip>
        <v-tooltip
          v-if="hoveredApiToken === apiToken.id"
          bottom
        >
          <template #activator="{ props }">
            <v-icon
              color="primary"
              class="left-gap-sm clickable"
              style="margin-top: 10px"
              size="16"
              v-bind="props"
              :disabled="!editingAllowed"
              @click="copyToClipboard(apiToken.api_token, $t('apiToken.copy'))"
            >
              fas fa-clipboard
            </v-icon>
          </template>
          {{ $t('apiToken.copy_api_token') }}
        </v-tooltip>
      </div>
    </div>
    <div>
      <v-btn
        style="box-shadow: none; margin-top: -30px;"
        target="_blank"
        color="primary"
        variant="outlined"
        :disabled="apiTokens.length >= maxAPITokens || !editingAllowed"
        @click="generateApiToken"
        rounded
      >
        <v-icon
          size="16"
          start
        >
          fas fa-plus
        </v-icon>
        {{ $t('settings.gen_api_token') }}
      </v-btn>
    </div>
    <ConfirmDialog
      v-model="revokeApiTokenDialog"
      :title="$t('apiToken.revoke')"
      :message="$t('apiToken.revoke_confirmation_message')"
      @confirm="revokeApiToken"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";

export default {
  name: 'ApiTokenSettings',

  components: {
    ConfirmDialog,
  },

  data() {
    return ({
      apiTokens: [],
      hoveredApiToken: null,
      selectedApiToken: null,
      revokeApiTokenDialog: false,
      renderingKey: 0,
      maxAPITokens: 10,
    })
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  mounted() {
    this.getApiTokens();
  },

  methods: {
    async copyToClipboard (url, message=this.$t('oidc_providers.callback_url_copied')) {
      await navigator.clipboard.writeText(url);
      await this.$store.commit('setSuccessMessage', message);
      this.$store.commit('setSuccessSnackbar', true);
    },

    async revokeApiToken() {
      try {
        await http.delete(`auth/api/v1/api-token/${this.selectedApiToken}`);
        this.renderingKey++;
        await this.getApiTokens();
        await this.$store.commit('setSuccessMessage', this.$t('apiToken.revoke_success'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.revokeApiTokenDialog = false;
      }
    },

    async getApiTokens() {
      try {
        this.loading = true;
        const params = {};
        params.app = this.app;
        const response = await http.get('auth/api/v1/api-token/', { params });
        this.apiTokens = response.data;
        this.totalApiTokens = parseInt(response.headers['x-total-count']);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loading = false;
      }
    },

    async generateApiToken() {
      try {
        this.loading = true;
        const response = await http.post('auth/api/v1/api-token/', {app: this.app});
        this.apiTokens.unshift(response.data);
        this.renderingKey++;
        await this.$store.commit('setSuccessMessage', this.$t('apiToken.generated'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loading = false;
      }
    },
  },

  props: {
    app: {
      type: String,
      default: 'extract',
    },

    editingAllowed: {
      type: Boolean,
      default: true,
    }
  },
}
</script>

<style>
  .api-token-field {
    width: 500px;
  }
</style>
