<template>
  <TableWithFooter
    :loading="loading"
    :paginated-items-length="pages[currentPage]?.length ?? 0"
    :total="filteredItems.length"
    :current-page="currentPage"
    :items-per-page="itemsPerPage"
    @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
    @change-page="(page) => currentPage = page"
    include-footer-in-table-card
  >
    <template #header>
      <v-col cols="auto" />
      <v-col cols="8">
        <div style="margin-left: 16px">
          {{ $t('datatable.header.dataPointName') }}
        </div>
      </v-col>
      <v-col class="justify-center">
        {{ $t('display') }}
      </v-col>
    </template>
    <template #body>
      <v-container
        class="pa-0"
        fluid
      >
        <draggable
          v-model="pages[currentPage]"
          ghost-class="ghost-dp"
          handle=".handle"
          @change="(event) => handleDrag(event, pages[currentPage])"
        >
          <v-row
            v-for="item in pages[currentPage]"
            :key="item.id"
            class="table-row fade-in table-row-height"
            style="border-bottom: 1px solid #eee !important"
          >
            <v-col cols="auto">
              <v-icon
                color="grey"
                size="17"
                class="clickable handle"
                style="opacity: 0.5"
              >
                fas fa-grip-vertical
              </v-icon>
            </v-col>
            <v-col cols="8">
              <ItemName
                :key="item.id"
                :ref="`name_${item.id}`"
                style="z-index: 202; max-width: calc(100% - 24px);"
                :item="item"
                :clickable="false"
              />
            </v-col>
            <v-col
              v-if="user.role === 'orgadmin'"
              class="justify-center"
            >
              <v-switch
                v-model="item.display_in_review"
                color="primary"
                density="dense"
                :disabled="disabled"
                @change="updateIntermediate(item)"
                hide-details
              />
            </v-col>
          </v-row>
        </draggable>
      </v-container>
    </template>
  </TableWithFooter>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import { VueDraggableNext } from "vue-draggable-next";
import { DataPointAPI } from '@/API/extract/DataPointAPI';
import { displaySnackbarSuccess } from '@/utils/SnackbarUtils';

export default {
  name: 'DataPointsReviewTable',

  components: {
    ItemName,
    TableWithFooter,
    draggable: VueDraggableNext,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },

    isAdmin() {
      return ['orgadmin', 'sysadmin'].includes(this.user.role);
    },

    selected: {
      get() {
        if (this.filteredItems.length > 0) {
          return this.filteredItems.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    pages: {
      get() {
        const pages = {};
        let page = 0;
        if (this.itemsPerPage > 0) {
          this.filteredItems.forEach((item, i) => {
            if (!(i % this.itemsPerPage)) {
              page++;
              pages[page] = [];
            }
            pages[page].push(item);
          });
        } else {
          pages[1] = [...this.filteredItems];
        }
        return pages;
      },
      set() {
        // pass
      }
    },
  },

  watch: {
    itemsPerPage() {
      this.resetCurrentPage();
    },
  },

  methods: {
    handleDrag(event, currentPageDataPoints) {
      const dataPoints = [...this.allItems];
      const currentIndex = (this.currentPage - 1) * this.itemsPerPage;
      this.updatePos(event.moved.element.id, currentIndex + event.moved.newIndex);
      // For smooth transition until position is updated, and items are reloaded
      dataPoints.splice(currentIndex, this.itemsPerPage, ...currentPageDataPoints);
      this.$emit('setItems', [...dataPoints]);
    },

    async updatePos(id, pos) {
      try {
        this.$store.commit('setLoadingScreen', true);
        await DataPointAPI.put({ id, position: pos });
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
        this.$emit('getItems');
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
      const items = this.allItems.map(item => {
        item.selected = false;
        return item;
      });
      this.$emit('setItems', [...items]);
    },

    updateIntermediate(dp) {
      // wait is required here to avoid the "glitching" of the switch
      const waitTime = 200;
      setTimeout(async () => {
        try {
          await DataPointAPI.put({ id: dp.id, intermediate: !dp.display_in_review });
          displaySnackbarSuccess(this.$t('docTypes.dataPoints.updated'));
          this.$emit('saveName');
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
      }, waitTime);
    },
  },

  props: {
    allItems: {
      type: Array,
      required: true,
    },

    filteredItems: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['setItems', 'getItems', 'saveName'],
}
</script>
