<template>
  <h4 class="inline-middle label mb-2">
    {{ $t(`docTypes.seamless_validation_token.name`) }}
  </h4>
  <v-switch
    v-model="internalExternalLinkSupportEnabled"
    class="inline-middle left-gap-sm"
    color="primary"
    :disabled="disabled"
    inset
  />
  <p
    class="bottom-gap-sm seamless-validation-token-message"
  >
    {{ $t(`docTypes.seamless_validation_token.message`) }}
  </p>
  <span class="right-gap-sm form-label font-weight-bold">
    {{ $t(`docTypes.seamless_validation_token_duration.name`) }}
  </span>
  <input
    v-model="internalTokenDuration"
    class="input-field right-gap-sm primary--text"
    placeholder="1"
    type="number"
    :min="1"
    :disabled="disabled"
  >
  <span class="right-gap form-label font-weight-bold">
    {{ $t(`docTypes.seamless_validation_token_duration.days`) }}
  </span>
</template>

<script>

export default {
  name: 'ExternalLinkConfiguration',

  data() {
    return {
      internalExternalLinkSupportEnabled: null,
      internalTokenDuration: null,
    };
  },

  watch: {
    internalExternalLinkSupportEnabled(newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.$emit('update:isExternalLinkSupportEnabled', newVal);
      }
    },

    internalTokenDuration(newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.$emit('update:tokenDuration', newVal);
      }
    },

    tokenDuration(newVal) {
      this.internalTokenDuration = newVal;
    },

    isExternalLinkSupportEnabled(newVal) {
      this.internalExternalLinkSupportEnabled = newVal;
    },
  },

  created() {
    this.internalTokenDuration = this.tokenDuration;
    this.internalExternalLinkSupportEnabled = this.isExternalLinkSupportEnabled;
  },

  props: {
    isExternalLinkSupportEnabled: {
      type: Boolean,
      required: true
    },

    tokenDuration: {
      type: [Number, null],
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'update:isExternalLinkSupportEnabled',
    'update:tokenDuration',
  ],
}

</script>

<style scoped>
.seamless-validation-token-message {
  margin-top: -30px;
  font-size: 0.8rem;
}
</style>

