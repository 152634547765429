<template>
  <div
    class="document-view py-5"
    :class="{ 'document-view--open-doc-viewer': selectedPage }"
  >
    <div
      v-if="loaded"
      class="d-flex align-center"
    >
      <BackLink
        class="mb-0"
        :show-text="false"
        :target="backLinkTarget"
      />
      <HeaderName
        style="max-width: 50%;"
        :item="{ name: document.filename, id: document.id }"
        :editable="false"
        @save="updateCollectionName"
      />
    </div>
    <h4
      v-if="context"
      class="text-h4 primary--text top-gap"
    >
      {{ $t('workflows.documents.additional_info') }}
    </h4>
    <textarea
      v-if="mergedPages.length > 0 && loaded"
      v-model="additionalInfo"
      class="warning-box top-gap-sm w-33"
      readonly
    />
    <v-checkbox
      v-model="allSelected"
      class="styled-checkbox bottom-gap   mt-0"
      :label="$t('workflows.documents.select_all_pages')"
      hide-details
    />
    <div
      v-if="mergedPages.length > 0 && loaded"
      class="d-flex flex-wrap fade-in"
      style="overflow: visible !important;"
    >
      <FileContainer
        v-for="merged, index in mergedPages"
        :key="index"
        class="file-container"
        :style="{
          'z-index': mergedPages.length - index,
        }"
        :classified-pages="merged"
        :last="index === mergedPages.length - 1"
        :current-cluster-index="index"
        :categories="categories"
        :category-map="categoryMap"
        :display-categories="document.is_bundle && selected.length === 0"
        :enable-breaks="document.is_bundle"
        :selected-page="selectedPage"
        @select-page="page => selectedPage = page"
        @refresh="getPages"
        @add-break="onAddBreak"
        @remove-break="onRemoveBreak"
      />
    </div>
    <div
      class="sidebar"
      :style="{width: `${sidebarWidth}px`}"
    >
      <v-icon
        class="clickable"
        style="position: absolute; left: 10px; top: 10px; color: #07002D"
        @click="selectedPage = null"
      >
        fas fa-chevron-right
      </v-icon>
      <div
        v-if="selectedPageImage"
        class="page background-spread fade-in clickable inline-middle vertical-centered"
        :style="{
          width: `${pageWidth}px`,
          height: `${pageHeight}px`,
          'background-image': `url(${selectedPageImage})`,
        }"
      />
    </div>
    <ClassificationCorrection
      v-if="document && document.status !== 'uploaded'"
      v-model="predictedCategory"
      :categories="categories"
      :fetch-datasets="handleFetchDatasets"
      :discard-reasons="discardReasons"
      :show-categories="document.is_bundle && selected.length > 0 || !document.is_bundle && selected.length === 0"
      :nb-selected-pages="selected.length"
      :sidebar-width="sidebarWidth"
      :submitting-review="submittingReview"
      :allow-custom-reason="!documentHasClassificationAgentDiscardReasons"
      :reviewer-comment="reviewerComment"
      @cancel-select-pages="deselectPages"
      @submit-review="submitReview"
      @submit-discard-reason="onSubmitDiscardReason"
      @copy-to-dataset="copyToDataset"
      @update:reviewer-comment="(value) => reviewerComment = value"
    />
  </div>
</template>
<script>
import { http } from '@/plugins/axios';
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import { ClassifyDocumentsAPI } from '@/API/classify/ClassifyDocumentsAPI';
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import { displaySnackbarError, displaySnackbarSuccess } from '@/utils/SnackbarUtils';

import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import FileContainer from '@/components/extract/elements/Workflows/PageCorrection/FileContainer';
import HeaderName from '@/components/common/elements/General/HeaderName.vue';
import ClassificationCorrection from '@/components/extract/views/Corrections/ClassificationCorrection.vue';

export default {
  name: 'DocumentClassificationReview',

  components: {
    BackLink,
    FileContainer,
    HeaderName,
    ClassificationCorrection,
  },

  data() {
    return {
      document: null,
      pages: [],
      model: {},
      statusCheck: null,
      loaded: false,
      downloading: false,
      updatedPages: [],
      updatingPages: false,
      selectedPage: null,
      pageImageMap: {},
      baseWidth: 500,
      submittingReview: false,
      jobEntry: null,
      predictedCategory: '',
      reviewerComment: '',
    }
  },

  computed: {
    isExternal() {
      return !!this.$route.params.token;
    },

    categoryMap() {
      return (this.categories || []).reduce((acc, category) => {
        acc[category.id] = category.name;
        return acc;
      }, {});
    },

    mergedPages() {
      return this.mergePages(this.pages);
    },

    selectedPageImage() {
      return this.selectedPage ? this.pageImageMap[this.selectedPage.id] : '';
    },

    ratio() {
      if (this.selectedPage && this.selectedPage.image_width && this.selectedPage.image_height) {
        return this.selectedPage.image_height / this.selectedPage.image_width;
      }
      return 1;
    },

    isPortrait() {
      return this.ratio > 1;
    },

    pageHeight() {
      const ratio = this.ratio;
      return this.baseWidth * ratio;
    },

    pageWidth() {
      const ratio = this.ratio;
      if (this.isPortrait) {
        return this.pageHeight / ratio;
      }
      return this.baseWidth;
    },

    sidebarWidth() {
      return this.selectedPage ? 600 : 0;
    },

    selected: {
      get() {
        if (this.pages.length > 0) {
          return this.pages.filter(page => page.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },

    documentHasClassificationAgentDiscardReasons() {
      return this.document.classification_agent?.reject_reasons?.length > 0;
    },

    discardReasons() {
      if (this.documentHasClassificationAgentDiscardReasons) {
        return this.getClassificationAgentDiscardReasons();
      }
      if (this.selected.length > 0) {
        return this.getPagesDiscardReasons();
      }
      return this.getDocumentDiscardReasons();
    },

    defaultDiscardReason() {
      return this.selected.length === 0 && this.$t('workflows.documents.file_corrupted') || this.$t('workflows.documents.blank_page')
    },

    entryInfo() {
      const webhookUrl = this.document?.webhook?.url;

      if (!webhookUrl) {
        return null;
      }

      if (!URL.canParse(webhookUrl)) {
        displaySnackbarError(this.$t('Unable to retrieve job info'));
        return null;
      }

      const urlObject = new URL(webhookUrl);
      const pathArray = urlObject.pathname.split('/');
      return {
        entryId: urlObject.searchParams.get('entry_id'),
        jobId: pathArray[pathArray.length - 2],
      }
    },

    context() {
      if (!(this.jobEntry && this.jobEntry.data)) {
        return null;
      }

      const result = Object.keys(this.jobEntry.data).reduce((res, key) => {
        res = this.jobEntry.data[key] && this.jobEntry.data[key].context || res;
        return res;
      }, null);

      return result && decodeURIComponent(result) || null;
    },

    additionalInfo() {
      return (
        this.context
        || (
          this.document && this.document.is_bundle && this.$t('workflows.documents.bundle_review_explanation')
          || this.$t('workflows.documents.classification_review_explanation')
        )
      )},

    categories() {
      if (!this.model?.categories) {
        return [];
      }

      if (!this.document.classification_agent) {
        return this.model.categories;
      }

      const categoriesItems = [];
      const classificationAgentCategoriesMap = this.document.classification_agent.categories_map;

      this.document.classification_agent.categories.forEach((category) => {
        if (Object.values(classificationAgentCategoriesMap).includes(category.name)) {
          categoriesItems.push({
            id: category.id,
            name: category.name,
          });
        }
        if (this.document.classification_agent.additional_categories.includes(category.name)) {
          categoriesItems.push({
            id: category.id,
            name: category.name,
          });
        }
      });

      this.model.categories.forEach(category => {
        if (!(category.name in classificationAgentCategoriesMap)) {
          categoriesItems.push({
            id: category.id,
            name: category.name,
          });
        }
      });

      return categoriesItems;
    },

    backLinkTarget() {
      if (this.document.classification_agent) {
        return {
          name: 'ClassificationAgentDocuments',
          params: { id: this.document.classification_agent.id },
        };
      }
      return {
        name: 'Corrections',
        query: { activeTab: 'classificationReview' },
      };
    },

    allSelected: {
      get() {
        return this.pages.every(page => page.selected);
      },
      set(value) {
        this.pages.forEach(page => page.selected = value);
      },
    },

    tokenParams() {
      return {
        noAuth: this.isExternal,
        external: this.isExternal,
        token: this.isExternal ? this.$route.params.token : null,
      }
    }
  },

  watch: {
    selectedPage(page) {
      if (page) {
        if (!this.pageImageMap[page.id]) {
          this.getImage();
        }
      }
    },

    entryInfo() {
      this.getJobEntry();
    },

    async predictedCategory(_, old) {
      if (old !== '' || this.document.is_bundle) {
        const data = {
          hitl_required: true,
        };
        let numberOfUpdates = 0;
        if (this.document.is_bundle) {
          const pageData = this.selected.map(page => ({
            page_id: page.id,
            category_id: this.predictedCategory,
          }));
          data.pages = pageData;
          numberOfUpdates = pageData.length;
          this.deselectPages();
        } else {
          data.category_id = this.predictedCategory;
          numberOfUpdates = 1;
        }
        try {
          await ClassifyDocumentsAPI.updateDocument(this.document.id, data, this.tokenParams);
          displaySnackbarSuccess(this.$tc('workflows.documents.categories_updated', numberOfUpdates));
          this.getPages();
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
      }
    },
  },

  async created() {
    await this.getDocument();
    await Promise.all([
      this.getModel(),
      this.getPages(),
    ]);
    this.loaded = true;
    const documentProbabilities = this.document.probabilities?.probabilities;
    if (documentProbabilities) {
      this.categories.sort((a, b) => {
        const probA = documentProbabilities[a.name] || 0;
        const probB = documentProbabilities[b.name] || 0;
        return probB - probA;
      });
    }

    if (!this.document.is_bundle) {
      this.predictedCategory = this.document.category_id;
    }
  },

  umounted() {
    clearTimeout(this.statusCheck);
  },

  methods: {
    async getJobEntry() {
      if (!this.entryInfo) {
        return
      }
      const { jobId, entryId } = this.entryInfo;
      try {
        this.jobEntry = await WorkflowAPI.getJobEntry(
          parseInt(jobId, 10),
          parseInt(entryId, 10),
          this.tokenParams,
        );
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async getImage() {
      try {
        const data = await ClassifyDocumentsAPI.getPageImage(
          this.$route.params.id,
          this.selectedPage.id,
          this.tokenParams,
        );
        this.pageImageMap = {
          ...this.pageImageMap,
          [this.selectedPage.id]: URL.createObjectURL(new Blob([data])),
        };
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async getDocument() {
      try {
        this.document = await ClassifyDocumentsAPI.getDocument(
          this.$route.params.id,
          this.tokenParams,
        );
      } catch (error) {
        this.error = true;
      }
    },

    async getModel() {
      try {
        this.model = await ClassifyModelAPI.getModel(this.document.model_id, this.tokenParams);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    updateCollectionName(name) {
      this.document.name = name;
    },

    async getPages() {
      try {
        const onlyNotDiscarded = true;
        this.pages = await ClassifyDocumentsAPI.getPages(this.document.id, onlyNotDiscarded, this.tokenParams);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    exportFile(response, filename) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    },

    async download() {
      try {
        this.downloading = true;
        const response = await http.get(`download/files/?pli_id=${this.$route.params.id}`, {
          responseType: "arraybuffer",
        });
        const fileName = this.document.name
          .replace(/,/g, "")
          .replace(/\.[^/.]+$/, ".zip");
        this.exportFile(response, fileName);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.downloading = false;
      }
    },

    mergePages(pages) {
      return pages.reduce((acc, page) => {
        if (!page.discarded) {
          acc.reverse();
          const previous = acc[0];
          acc.reverse();
          if (previous && previous.category_id === page.category_id) {
            previous.pages.push(page)
          } else {
            acc.push({
              id: Math.random(),
              category_id: page.category_id,
              pages: [page],
              breaks: this.document.breaks.map(breakIndex => {
                const previousPagesLength = acc.reduce((sum, cluster) => sum + cluster.pages.length, 0);
                return breakIndex - previousPagesLength;
              }),
            });
          }
        }
        return acc;
      }, []);
    },

    deselectPages() {
      this.allSelected = false;
    },

    async submitReview() {
      try {
        this.submittingReview = true;
        await ClassifyDocumentsAPI.validateDocument(
          this.document.id,
          this.reviewerComment || null,
          this.tokenParams,
        );
        displaySnackbarSuccess(this.$t('workflows.documents.review_submitted'));
        this.$router.push(this.backLinkTarget);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.submittingReview = false;
      }
    },

    getDocumentDiscardReasons (){
      const reasons = [
        'file_corrupted',
        'unreadable_content',
        'other'
      ];

      return reasons.map(reason => ({
        title: this.$t(`workflows.documents.${reason}`),
        value: this.$t(`workflows.documents.${reason}`)
      }));
    },

    getPagesDiscardReasons() {
      const reasons = [
        'blank_page',
        'unreadable_content',
        'other',
      ];

      return reasons.map(reason => ({
        title: this.$t(`workflows.documents.${reason}`),
        value: this.$t(`workflows.documents.${reason}`)
      }));
    },

    getClassificationAgentDiscardReasons() {
      return this.document.classification_agent.reject_reasons.map((reason) => ({
        title: reason,
        value: reason,
      }));
    },

    async submitDiscardDocument(discardReason) {
      try {
        this.submittingReview = true;
        await ClassifyDocumentsAPI.updateDocument(
          this.document.id,
          { discard_reason: discardReason },
          this.tokenParams,
        );
        await ClassifyDocumentsAPI.validateDocument(
          this.document.id,
          this.reviewerComment || null,
          this.tokenParams,
        );
        displaySnackbarSuccess(this.$t('workflows.documents.review_discarded'));
        this.$router.push(this.backLinkTarget);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.submittingReview = false;
      }
    },

    async onSubmitDiscardReason(discardReason){
      if (this.selected.length === 0) {
        await this.submitDiscardDocument(discardReason);
      } else {
        if (this.pages.length === 1 && this.selected.length === 1) {
          await this.submitDiscardDocument(discardReason);
        } else {
          const pageData = this.selected.map(page => {
            return {
              page_id: page.id,
              discard_reason: discardReason,
            };
          });
          const data = {
            pages: pageData,
            hitl_required: true,
          }
          try {
            await ClassifyDocumentsAPI.updateDocument(this.document.id, data, this.tokenParams);
            this.deselectPages();
            this.getPages();
          } catch (error) {
            error.handleGlobally && error.handleGlobally();
          }
        }
      }
    },

    async handleFetchDatasets({ offset, limit }) {
      try {
        const response = await DatasetAPI.get(offset, limit);
        return response;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    copyToDataset(data) {
      const selected_pages_numbers = this.selected.map(page => page.page_number);
      ClassifyDocumentsAPI.copyToDataset(
        this.document.id,
        data.dataset_id,
        data.entry_name,
        selected_pages_numbers
      )
        .then(() => {
          displaySnackbarSuccess(this.$t('workflows.documents.copy_to_dataset_success'));
        })
        .catch((error) => {
          error.handleGlobally && error.handleGlobally();
        });
    },

    async onAddBreak(break_index, current_index) {
      try {
        const offset = this.mergedPages.slice(0, current_index).reduce((acc, merged) => acc + merged.pages.length, 0);
        const data = {
          breaks: [...this.document.breaks, offset + break_index],
          hitl_required: true,
        };
        await ClassifyDocumentsAPI.updateDocument(this.document.id, data, this.tokenParams);
        await this.getDocument();
        await this.getPages();
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async onRemoveBreak(break_index, current_index) {
      try {
        const offset = this.mergedPages.slice(0, current_index).reduce((acc, merged) => acc + merged.pages.length, 0);
        const data = {
          breaks: this.document.breaks.filter(breakIndex => breakIndex !== offset + break_index),
          hitl_required: true,
        };
        await ClassifyDocumentsAPI.updateDocument(this.document.id, data, this.tokenParams);
        await this.getDocument();
        await this.getPages();
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.document-view {
  padding: 40px 40px 350px 60px !important;
  min-height: 100vh;
  position: relative;

  &--open-doc-viewer.closed-router {
    width: calc(100vw - 810px);
  }

  &--open-doc-viewer.open-router {
    width: calc(100vw - 660px);
  }

  .sidebar {
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
    background-color: darkgray;
    transition: width 300ms;
    text-align: center;
    z-index: 1000;
  }

  .background-spread {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .file-container {
    overflow: clip;
    overflow-clip-margin: 1000px;
  }

  .warning-box {
    background-color: rgba(var(--v-theme-primary), 0.25);
    border: 1px solid rgb(var(--v-theme-primary));
    border-radius: 5px;
    font-size: 0.8rem;
    font-style: italic;
    padding: 10px 15px;
    resize: none;
    field-sizing: content;

    &:focus {
      outline: none;
    }
  }

  .styled-checkbox {
    border: 1px solid rgb(var(--v-theme-grey-darken2));
    background-color: white;
    border-radius: 4px;
    padding-right: 20px;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: fit-content;
  }
}
</style>
